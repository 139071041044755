<template>
  <q-list style="min-width: 100px">
    <q-item clickable to="/indicadores">
      <q-item-section>Indicadores</q-item-section>
    </q-item>
    <q-separator></q-separator>
    <q-item clickable to="/vinculacionexterna">
      <q-item-section>Vinculación Externa</q-item-section>
    </q-item>
    <q-separator></q-separator>
    <q-item clickable to="/vinculacioninterna">
      <q-item-section>Vinculación Interna</q-item-section>
    </q-item>
  </q-list>
</template>

<script lang="ts">
import {
  defineComponent,
  // defineAsyncComponent,
  // ref,
  onMounted,
} from "vue";

export default defineComponent({
  name: "MainMenu",
  components: {
    // MarqueeText: defineAsyncComponent(() => import("@/components/MarqueeText.vue"))
    // MainMenu: defineAsyncComponent(
    //   () => import("./PageContainer.vue")
    // ),
  },
  setup() {
    console.log("MainMenu ready");
    // handleClientLoad();
    // const leftDrawerOpen = ref(false);
    // const rightDrawerOpen = ref(false);
    onMounted(() => {
      // console.log(root.value);
    });
    return {
      // toggleLeftDrawer() {
      //   leftDrawerOpen.value = !leftDrawerOpen.value;
      // },
      // rightDrawerOpen,
      // toggleRightDrawer() {
      //   rightDrawerOpen.value = !rightDrawerOpen.value;
      // },
    };
  },
});
</script>

<style lang="scss">
.q-item:hover {
  background-color: rgba(0, 27, 88, 0.7) !important;
  color: white !important;
}
// .q-focus-helper {
//   transition: rgba(0, 27, 88, 0.7) 0.3s cubic-bezier(0, 27, 88, 0.7), opacity 0.4s cubic-bezier(0, 27, 88, 0.7);
// }
// body.desktop .q-focusable:focus > .q-focus-helper, body.desktop .q-manual-focusable--focused > .q-focus-helper, body.desktop .q-hoverable:hover > .q-focus-helper {
//   background:rgba(0, 27, 88, 0.99);
//   // color: white;
//   opacity:  0.7;
// }
</style>
